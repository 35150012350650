<template>
  <div id="shop">
    <div class="page-title-area padding-50-pr bg-6" data-aos="fade-up" data-aos-delay="0" data-aos-duration="1000">
      <div class="container">
        <div class="page-title-content">
          <h2>Spread the PeaceJam Magic</h2>
        </div>
      </div>
    </div>

    <section class="faq-contact-area ptb-100" data-aos="fade-up" data-aos-delay="300" data-aos-duration="1000">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="contact-wrap">
              <div class="contact-form">
                <div class="row justify-content-center">
                    <div class="col-lg-4 col-md-6 mb-4" v-for="item in shopdata" :key="item.id">
                        <div class="blog-item">
                          <a :href="item.link" target="_blank" rel="noreferrer">
                            <div class="image">
                                <img :src="item.image" class="shop-image" alt="Purchase Curriculum" />
                            </div>
                            <div class="description text-center">
                                <h3>{{ item.title }}</h3>
                            </div>
                          </a>
                        </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>

  export default {
    name: "Shop",
    data() {
      return {
        shopdata: [
            { id: 1, image: require('@/assets/img/shop/merchandise-picture.jpg'), title: 'Merchandise', link: 'https://peacejamorg.myshopify.com/collections' },
            { id: 2, image: require('@/assets/img/shop/purchase-curriculum.jpg'), title: 'Purchase Curriculum', link: 'https://peacejam.thinkific.com/' },
            { id: 3, image: require('@/assets/img/shop/films.jpg'), title: 'Films', link: 'https://peacejamorg.myshopify.com/collections/films' },
        ]
      }
    },
    mounted() {
      window.scrollTo(0, 0);
    },
  }
</script>

<style scoped lang="scss">
  .image {
    height: 300px;
    overflow: hidden;
    margin: 0 auto;
    margin-bottom: 20px;

    .shop-image {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .blog-item h3 {
    font-size: 21px;
    transition: 0.5s;
  }

</style>
